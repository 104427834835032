import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import { Auth0Plugin } from './plugins/auth0'
import nalert from './plugins/nalert'

Vue.config.productionTip = false
Vue.use(nalert);

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
  onRedirectCallback: (appState) => {
    console.log('onRedirectCallback', appState);
    router.push(
      appState && appState.targetPath
        ? appState.targetPath
        : window.location.pathname
    );
  },
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
