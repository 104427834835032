import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
   // { path: '/login', name: 'Login', component: () => import(/* webpackChunkName: "login" */ '../views/Login') },
   { path: '/', name: 'Home', component: Home },
   { path: '/profile', name: 'Profile', component: () => import(/* webpackChunkName: "profile" */ '../views/Profile') },
   {
      path: '/ruang-tamu',
      name: 'Ruang Tamu',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "ruangtamu" */ '../views/RuangTamu.vue')
   },
   { path: '/teras', name: 'Teras', component: () => import(/* webpackChunkName: "teras" */ '../views/Teras') },
   { path: '/dapur', name: 'Dapur', component: () => import(/* webpackChunkName: "dapur" */ '../views/dapur') },
   { path: '/kamar1', name: 'Kamar 1', component: () => import(/* webpackChunkName: "kamar1" */ '../views/kamar1') },
   { path: '/kamar2', name: 'Kamar 2', component: () => import(/* webpackChunkName: "kamar2" */ '../views/kamar2') },
   { path: '/callback', name: 'Callback', component: () => import(/* webpackChunkName: "callback" */ '../views/CallBack') },
   { path: '*', name: 'Not Found', component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound') }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

export default router
