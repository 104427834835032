import Vue from 'vue'
import Vuex from 'vuex'
import CFG from '../../config'
import { teras, node, fb } from './rtdb'
// import auth from './auth'

Vue.use(Vuex)
let soc, ttc, timer_soc;
const connect = () => {
   if(soc) clearTimeout(timer_soc);
   if(!soc || soc.readyState === 3) soc = new WebSocket(CFG.soc_url);
   else if(soc.readyState === 0) console.log('Connecting to session server ...');
   else return;
   soc.onopen = () => {
      // const token = cookies.get(CFG.tokenKey);
      // if(token) {
      //    soc.send( JSON.stringify({typ:'login', sid: this.sid, token: token}) );
      // }
   }
   soc.onclose = ev => {
      if(ev.wasClean) return;
      console.log('closing:', ev);
      ttc = ttc+1000||1000;
      if(ttc > 1800000) ttc = 1800000;
      timer_soc = setTimeout(connect, ttc);
   }
   soc.onmessage = message => {
      const arr = message.data.split(';');
      switch(arr[0]) {
         case 'upd':
            vuex.commit('set', {[arr[1]]:arr[2]});
            break;
         case 'init':
            arr[1].split(',').forEach(d => {
               const o = d.split(':');
               vuex.commit('set', {[o[0]]:o[1]});
            });
         break;
         default:
      }
   }
   soc.onerror = function(evt) { console.log('something goes wrong :', evt) };
}
typeof connect
// connect();

const vuex = new Vuex.Store({
   state: {
      drawer: true,
      alert: [],
      user: {},
      transport: 'rtdb',
      teras_temp:0, suhu1:0, suhu2:0, solar_volt:0, accu:0, dc_cur:0
   },
   mutations: {
      set(state, arr) {
         console.log('set :', arr);
         for(let i in arr) state[i] = arr[i];
      },
      toggleDrawer(state) {
         state.drawer = !state.drawer;
      },
      addAlert(state, data) {
         state.alert.push(data);
      },
      delAlert(state, idx) {
         state.alert.splice(idx, 1);
      },
   },
   actions: {
      send({commit}, data) {
         console.log('disp', data);
         if(soc && soc.readyState === 1) for(let i in data) soc.send(`set;${i};${data[i]}`);
         else fetch(CFG.api_url, {method: 'POST', body: JSON.stringify(data)});
         commit('set', data);
      }
   },
   modules: {
      teras,
      node,
      fb,
      // auth,
   }
});

export default vuex;
