<template>
  <v-timeline align-top dense >
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      :color="item.color"
      :icon="item.icon"
      fill-dot
    >
      <div class="text-h5">{{ item.title }}</div>
      <p>Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.</p>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
  export default {
    data: () => ({
      items: [
        {
          title: 'Temperatur',
          color: 'red lighten-2',
          icon: 'mdi-home-thermometer',
        },
        {
          title: 'Power Consumtion',
          color: 'purple darken-1',
          icon: 'mdi-home-lightning-bolt',
        },
        {
          title: 'Solar Grid',
          color: 'green lighten-1',
          icon: 'mdi-solar-power-variant',
        },
        {
          title: 'Hidropnik',
          color: 'indigo',
          icon: 'mdi-sprout',
        },
      ],
    }),
  }
</script>