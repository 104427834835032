<template>
   <v-form @submit.prevent="login">
      <v-btn :disabled="!is_forgot" color="primary" small text @click="is_forgot=!is_forgot">Login</v-btn>|<v-btn small :disabled="is_forgot" color="primary" text @click="is_forgot=!is_forgot">forgot password</v-btn>
      <p v-if="is_forgot">Silahkan masukkan email anda</p>
      <p v-else>Silahkan login untuk melanjutkan</p>
      <v-text-field append-icon="mdi-account" label="Email" type="text" v-model="fm.email" :rules="usrRules" />
      <v-text-field v-if="!is_forgot" append-icon="mdi-account-key" label="Password" type="password" v-model="fm.password" :rules="pwdRules" />
      <v-btn outlined rounded min-width="120px" color="primary" type="submit" :loading="loading">{{is_forgot?'reset password':'Login'}}</v-btn>
   </v-form>
</template>
<script>
export default {
   data() {
      return {
         fm: {},
         usrRules: [v => !!v || 'Email harus diisi' ],
         pwdRules: [v => !!v || 'Password harus diisi' ],
         loading: false,
         message: '',
         is_forgot: false
      };
   },
   methods: {
      login() {
         this.loading = true;
         if(this.is_forgot) {
            this.$store.dispatch('fb/resetPwd', this.fm.email)
            .finally(() => {
               this.loading = false;
               this.is_forgot = false;
            });
         } else {
            this.$store.dispatch('fb/login', this.fm).finally(() => {
               this.loading = false;
            });
         }
      },
   }
};
</script>
