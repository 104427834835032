<template>
   <v-row align="center" style="height:100%">
      <v-col cols="5" md="2">
         <v-img src="@/assets/smart-home.png" aspect-ratio="1.2" />
      </v-col>
      <v-col cols="7" md="4">
         <div class="text-h3 mb-4">Welcome Home</div>
         <login-form v-if="!$store.state.user" />
         <chart v-else :data="chartCfg"/>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-md-column align-md-end">
         <hello-world/>
         <!-- <div class="text-h5 mt-3">Temperature</div>
         <div class="text-h3">{{teras_temp}}°</div>
         <div class="text-h5 mt-3">Solar Voltage</div>
         <div class="text-h3">{{solar_volt}}V</div>
         <div class="text-h5 mt-3">Power Comsumtion</div>
         <div class="text-h3">{{solar_volt}}W</div> -->
      </v-col>
   </v-row>
</template>

<script>
import Chart from '../components/Chart'
import {mapState} from 'vuex'
import HelloWorld from '../components/HelloWorld.vue'
import LoginForm from '../components/LoginForm.vue'
export default {
   components: { Chart, HelloWorld, LoginForm },
   data: () => ({
      chartCfg: {
         // datasets: [{ }],
         // labels: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
      },
   }),
   computed: {
      ...mapState(['solar_volt', 'teras_temp',]),
      toggleTrans: {
         get() {
            return this.$store.state.transport === 'rtdb'
         },
         set(val) {
            this.$store.commit('set', {transport: val ? 'rtdb' : 'local'})
         }
      }
   },
}
</script>