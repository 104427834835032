export const links = [
   { to: '/', icon: 'mdi-view-dashboard', text: 'Home' },
   { to: '/ruang-tamu', icon: 'mdi-account', text: 'Ruang Tamu' },
   { to: '/dapur', icon: 'mdi-clipboard-outline', text: 'Dapur' },
   { to: '/teras', icon: 'mdi-format-font', text: 'Teras' },
   { to: '/kamar1', icon: 'mdi-chart-bubble', text: 'Kamar 1' },
   { to: '/kamar2', icon: 'mdi-map-marker', text: 'Kamar 2' },
];

export const nodes = [
   {
      link: '/',
      icon: 'mdi-view-dashboard',
      name: 'Home',
      devices: [
         { name: 'Lampu Utama', icon: 'mdi-lightbulb-on-outline', type: 'switch', id: 'lampu-utama', state: false },
      ]
   },
   {
      link: '/ruang-tamu',
      icon: 'mdi-account',
      name: 'Ruang Tamu',
      devices: [
         { name: 'Temperature', icon: 'mdi-thermometer', type: 'display', dev: 'nodes', id: 'suhu1', unit: '°C' },
         { name: 'Lampu 1', icon: 'mdi-lightbulb-variant-outline', type: 'slide', id: 'rt1', dev: 'node' },
         { name: 'Lampu 2', icon: 'mdi-lightbulb-variant-outline', type: 'slide', id: 'rt2', dev: 'node' },
         { name: 'Ceiling Fan', icon: 'mdi-ceiling-fan', type: 'slide', id: 'cf1', dev: 'node' },
      ]
   },
   {
      link: '/dapur',
      icon: 'mdi-clipboard-outline',
      name: 'Dapur',
      devices: [
         { name: 'Water Storage', icon: 'mdi-storage-tank-outline', type: 'display', id: 'water_tank', unit: '%' },
         { name: 'Lampu', icon: 'mdi-lightbulb-variant-outline', type: 'slide', id: 'dapur', dev: 'node' },
      ]
   },
   {
      link: '/teras',
      icon: 'mdi-format-font',
      name: 'Teras',
      devices: [
      
      ]
   },
   {
      link: '/kamar1',
      icon: 'mdi-chart-bubble',
      name: 'Kamar 1',
      devices: [
         { name: 'Temperature', icon: 'mdi-thermometer', type: 'display', id: 'suhu1', unit: '°C' },
         { name: 'Lampu', icon: 'mdi-lightbulb-variant-outline', type: 'slide', id: 'kt1', dev: 'node' },
      ]
   },
   {
      link: '/kamar2',
      icon: 'mdi-map-marker',
      name: 'Kamar 2',
      devices: [
         { name: 'Temperature', icon: 'mdi-thermometer', type: 'display', id: 'suhu2', unit: '°C' },
         { name: 'Lampu', icon: 'mdi-lightbulb-variant-outline', type: 'slide', id: 'kt2', dev: 'node' },
      ]
   },
];

export default {
   api_url: 'http://192.168.192.5',
   soc_url: 'ws://192.168.0.33:81',
}
