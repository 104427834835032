import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { browserSessionPersistence, getAuth, onAuthStateChanged, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword, signOut, updateProfile} from "firebase/auth";
import vuex from ".";
// import router from "../router";

const firebaseConfig = {
   apiKey: process.env.VUE_APP_RTDB_API_KEY,
   authDomain: process.env.VUE_APP_RTDB_AUTH_DOMAIN,
   databaseURL: process.env.VUE_APP_RTDB_URL,
   projectId: process.env.VUE_APP_RTDB_PROJECT_ID,
   storageBucket: process.env.VUE_APP_RTDB_STORAGE_BUCKET,
   messagingSenderId: process.env.VUE_APP_RTDB_MESSAGING_SENDER_ID,
   appId: process.env.VUE_APP_RTDB_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const nodeRef = ref(db, 'nodes');
const terasRef = ref(db, 'teras');
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
   if (user) {
      vuex.commit('set', {user});
     // User is signed in, see docs for a list of available properties
     // https://firebase.google.com/docs/reference/js/firebase.User
   } else {
      vuex.commit('set', {user: null});
   }
});

onValue(ref(db, 'data'), (snapshot) => {
   vuex.commit('set', snapshot.val());
});

onValue(nodeRef, (snapshot) => {
   vuex.commit('node/set', snapshot.val());
});

onValue(terasRef, (snapshot) => {
   vuex.commit('teras/set', snapshot.val());
});

export const node = {
   namespaced: true,
   state: {cf1: 0, cf3: 94, dapur: 255, ip: "192.168.192.5", kt1: 38, kt1b: 255, kt1g: 121, kt1r: 170, kt2: 255, plafon: 90, rt1: 0, rt2: 250},
   actions: {
      set({state, commit}, data) {
         // cookies.set(CFG.tokenKey, token, {expires: 1});
         // axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
         if(vuex.state.transport === 'rtdb') {
            console.log('disp set node', data);
            update(nodeRef, data);
         } else {
            for(let i in data) fetch(`http://${state.ip}/set`, {
               method: 'POST',
               headers: {
                  'Content-Type': 'urlencoded',
               },
               body: `io=${i}&val=${data[i]}`,
            });
         }
         commit('set', data);
      },
   },
   mutations: {
      set(state, data) {
         console.log('set node', data);
         for(let i in data) state[i] = data[i];
      },
   }
}

export const teras = {
   namespaced: true,
   state: {grow_led: 0, ip: "192.168.192.8", lamp: 2, pump1: 2, pump1_interval: 3600, pump1_on_time: 35, pump2: 0, pump2_interval: 600, pump2_on_time: 15, pump_off_voltage: 11, teras_on_voltage: 3},
   actions: {
      set({state}, data) {
         // cookies.set(CFG.tokenKey, token, {expires: 1});
         // axios.defaults.headers.common[CFG.tokenHeaderKey] = token;
         if(vuex.state.transport === 'rtdb') {
            update(terasRef, data);
         } else {
            fetch(`http://${state.ip}/api/teras`, {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json'
               },
               body: JSON.stringify(data)
            });
         }
         // commit('set', data);
      },
   },
   mutations: {
      set(state, data) {
         console.log('set teras', data);
         for(let i in data) state[i] = data[i];
      },
   }
}

export const fb = {
   namespaced: true,
   state: {},
   actions: {
      login({commit}, {email, password}) {
         setPersistence(auth, browserSessionPersistence).then(() => {
            return signInWithEmailAndPassword(auth, email, password);
         }).catch((error) => {
            commit('addAlert', {title: 'Ooops!', text: error.message, type: 'warning'}, { root: true });
         });
      },
      logout({commit}) {
         signOut(auth).catch(error => {
            console.log('logout:', error);
            commit('addAlert', {title: 'Ooops!', text: 'Logout fail', type: 'warning'}, { root: true });
         });
      },
      resetPwd({commit}, email) {
         sendPasswordResetEmail(auth, email).then(() => {
            commit('addAlert', {title: 'Success', text: 'Reset password email sent', type: 'success'}, { root: true });
         }).catch((error) => {
            commit('addAlert', {title: 'Ooops!', text: error.message, type: 'warning'}, { root: true });
         });
      },
      async update({commit}, {phoneNumber, displayName, photoURL}) {
         return updateProfile(auth.currentUser, {
           phoneNumber,
           displayName,
           photoURL,
         }).then(() => {
            commit('addAlert', {title: 'Ooops!', text: 'Success update profile', type: 'info'}, { root: true });
            commit('set', {user: auth.currentUser}, { root: true });
         }).catch((error) => {
            commit('addAlert', {title: 'Ooops!', text: 'Error updating user', type: 'error'}, { root: true });
            console.log('Error updating user:', error);
         });
      }
   },
   mutations: {
   }
}
