<template>
   <canvas ref="canvas"></canvas>
</template>
<script>
const config = {
   type: 'line',
   data: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
         label: 'Suhu perjam',
         backgroundColor: 'red',
         borderColor: 'red',
         data: [ 31, 34, 32, 35, 33, 34, 30 ],
         fill: false,
      }]
   },
   options: {
      responsive: true,
      plugins: {
         title: {
            display: true,
            text: 'Chart.js Line Chart - Cubic interpolation mode'
         },
      },
      interaction: {
         intersect: false,
      },
      scales: {
         x: {
            display: true,
            title: {
               display: true
            }
         },
         y: {
            display: true,
            title: {
               display: true,
               text: 'Value'
            },
            suggestedMin: -10,
            suggestedMax: 200
         }
      }
   }
};
import {Chart, CategoryScale, LinearScale, LineController, PointElement, LineElement} from 'chart.js';
Chart.register(
   CategoryScale,
   LinearScale,
   LineController,
   LineElement,
   PointElement,
);
let chart;
export default {
   name: 'chart',
   props: ['data', 'opts', 'title'],
   watch: {
      data(val) {
         config.data = val;
         chart.update();
      }
   },
   mounted() {
      config.data = this.data;
      if(this.title) config.options.plugins.title = { display: true, text: this.title };
      if(this.opts) config.options = {...config.options, ...this.opts}
      chart = new Chart(this.$refs.canvas.getContext('2d'), config);
   },
   methods: {
      update(val) {
         config.data.datasets.data.push(val);
         chart.update();
      }
   }
}
</script>