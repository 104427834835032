<template>
   <v-app-bar app flat>
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-img src="@/assets/smart-home.png" alt="sh-logo" class="shrink mr-2" height="40" width="60"/>
      <v-toolbar-title style="cursor: pointer" @click="$router.push('/')">SmartHome</v-toolbar-title>

      <!-- <div class="v-toolbar-title">
         <v-toolbar-title class="tertiary--text font-weight-light" >
            <v-app-bar-nav-icon v-if="responsive" color="green" @click="$store.commit('toggleDrawer')" />
            {{ title }}
         </v-toolbar-title>
      </div> -->

      <v-spacer />
      <v-toolbar-items>
         <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
               <v-btn text v-bind="attrs" v-on="on" >Devices</v-btn>
            </template>
            <!-- <v-container> -->
               <v-card>
                  <v-card-text>
                     <v-row justify="space-around">
                        <v-col v-for="(link, i) in links" :key="i">
                           <v-btn min-width="160" rounded outlined color="primary" :to="link.to" text>{{link.text}}</v-btn>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
            <!-- </v-container> -->
         </v-menu>
         <v-menu content-class="dropdown-menu" offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
               <v-btn icon v-on="on" v-bind="attrs">
                  <v-icon color="primary">mdi-account-circle</v-icon>
               </v-btn>
            </template>
            
            <v-card min-width="180">
               <v-list>
                  <v-list-item>
                     <v-list-item-avatar>
                        <img :src="$store.state.user.photoURL" alt="avatar"/>
                     </v-list-item-avatar>

                     <v-list-item-content>
                        <v-list-item-title class="text-h6">{{$store.state.user.displayName}}</v-list-item-title>
                        <v-list-item-subtitle>{{$store.state.user.email}}</v-list-item-subtitle>
                     </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                     <v-list-item-action>
                        <v-switch class="mr-2" dense v-model="$vuetify.theme.dark" />
                     </v-list-item-action>
                     <v-list-item-title>Dark Mode</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/profile">
                     <v-list-item-icon>
                        <v-icon>mdi-account-eye-outline</v-icon>
                     </v-list-item-icon>
                     <v-list-item-content>
                        <v-list-item-title>Profile</v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="logout">
                     <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                     </v-list-item-icon>
                     <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item-group color="primary">
                     <v-list-item >
                        <v-list-item-icon>
                           <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content @click="logout">
                           <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </v-list-item-group> -->
               </v-list>
            </v-card>
         </v-menu>
      </v-toolbar-items>
   </v-app-bar>
</template>

<script>
import { links } from '../../config'
export default {
   data: () => ({
      devices: [
         {title: 'teras'},
      ],
      links,
      title: null,
      responsive: false
   }),

   watch: {
      '$route' (val) {
         this.title = val.name
      }
   },

   mounted () {
      this.onResponsiveInverted()
      window.addEventListener('resize', this.onResponsiveInverted)
   },
   beforeDestroy () {
      window.removeEventListener('resize', this.onResponsiveInverted)
   },

   methods: {
      onResponsiveInverted () {
         if (window.innerWidth < 800) {
         this.responsive = true
         } else {
         this.responsive = false
         }
      },
      logout() {
         this.$store.dispatch('fb/logout');
      },
   }
}
</script>

<style>
#core-toolbar a {
   text-decoration: none;
}
</style>
