<template>
   <v-app >
      <!-- <drawer /> -->
      <toolbar v-if="$store.state.user" />
      <v-main>
         <v-container style="height:100%">
            <v-fade-transition mode="out-in">
               <router-view />
            </v-fade-transition>
         </v-container>
         <v-overlay :value="$auth0.isLoading">
            <v-progress-circular indeterminate size="64" />
         </v-overlay>
      </v-main>
      <alert/>
   </v-app>
</template>
<script>
// import Drawer from './components/Drawer'
import Toolbar from './components/Toolbar'
import Alert from './components/Alert.vue';
export default {
   name: 'app',
   components: {
      // Drawer,
      Toolbar,
      Alert
   }
}
</script>
<style lang="scss">
html, body {height: 100%;}
#app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   height: 100%;
}

#nav {
   padding: 30px;

   a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
         color: #42b983;
      }
   }
}
</style>
